import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

const SearchBar = (props) => (
    <div className="search-bar">
        <input
            className="search-text"
            type="text"
            value={props.value}
            id="header-search"
            placeholder="Search for Numbers"
            onChange={props.onChange}
            onKeyPress={event => {
                if (event.key == "Enter")
                    props.onSubmit()
            }}
        />
    </div>
);

const Result = (props) => {
    return <div className="result">
        <div className="result-content">
            <a href={props.result.links}>
                <h3>{props.result.number}</h3>
                <div>{props.result.titles}</div>
            </a>
            <div>{props.result.descriptions}</div>
        </div>
    </div>
}
const Results = (props) => {
    const results = props.results.map((r) => <li>
        <Result
            result={r}
        />
    </li>)
    return <div>
        <p>{props.status == "error"
            ? "error, please try another query"
            : props.status}</p>
        <ol>{results}</ol>
    </div>
}

class SearchEngine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "query": "",
            "status": "no-query",
            "results": []
        }
    }

    onSubmit() {
        const server = "https://www.14285714.xyz/api"
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "query": this.state.query
            })
        };
        this.setState({
            "status": "pending",
            "results": [],
        });
        fetch(server, requestOptions).then(
            res => res.json()
        ).then(data =>
            this.setState({
                "status": data["status"],
                "results": data["response"]
            })
        )
    }

    render() {
        return (
            <div>
                <SearchBar
                    value={this.state.query}
                    onChange={(event) => {
                        this.setState({ "query": event.target.value })
                    }}
                    onSubmit={() => this.onSubmit()}
                />
                <Results
                    status={this.state.status}
                    results={this.state.results}
                />
            </div>
        );
    }
}

// ========================================

ReactDOM.render(
    <SearchEngine />,
    document.getElementById('root')
);
